import path0 from './feature_flags/app/typescript/@featureFlags/Bootable'
import path1 from './form/app/typescript/@form/Bootable'
import path2 from './tags/app/typescript/@tags/Bootable'
import path3 from './dashboard/app/typescript/@dashboard/Bootable'
export default {
...path0,
...path1,
...path2,
...path3,
}
