import { NodeConstructorArgs } from "./NodeWrapper";

type TreeKey = string | symbol | number

export class TreeStructure<Element extends TreeStructure<any>> {
  parent?: Element;
  key?: string;

  constructor(args: NodeConstructorArgs<any, Element>) {
    switch (args.type) {
      case 'nested':
        this.parent = args.parent
        this.key = args.key
        break;
      case 'root':
        break;
      case 'related':
        break
    }
  }

  get keyPath(): string[] {
    if (this.parent !== undefined) {
      return [...this.parent.keyPath, this.key];
    }
    return [];
  }

  get isRoot(): boolean {
    return this.parent == undefined;
  }

  get root(): Element {
    return this.isRoot ? this : this.parent.root;
  }

  get ancestors(): Element[] {
    if (this.parent) {
      return [...this.parent.ancestors, this.parent];
    }
    return [];
  }

  _children: { [Key in TreeKey]?: Element } = {};

  get children(): Element[] {
    return Object.values(this._children);
  }

  get descendents(): Element[] {
    return this.children.map(v => [v, ...v.descendents]).flat();
  }

  treeChild<R extends Element>(key: TreeKey, create?: () => R): R {
    this._children[key] ||= create && create();
    return this._children[key] as R;
  }
}
