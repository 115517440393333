import React from 'react'
import { useState } from 'react'
import { Bars3Icon, BellIcon } from '@heroicons/react/24/outline'

import { LayoutProps } from '.'

import { Sidebar } from './Sidebar'
import { LinkList } from './Sidebar/LinkList'
import { StickyWrapper } from 'components/Util'
import { useRouteMetadata } from '@dashboard/Routing'
import { UserNavigationMenu } from './UserNavigation'

export const FooterPortalContext = React.createContext<React.MutableRefObject<HTMLDivElement>>(null)

const Layout: React.FunctionComponent<LayoutProps> = ({ search, navigation, userNavigation, navigationBottom, topPanel, title, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const footerPortal = React.useRef<HTMLDivElement>()

  const { framePadding } = useRouteMetadata()

  return <>
    <Sidebar className="w-sb" open={sidebarOpen} onClose={() => setSidebarOpen(false)}>
      <div className="flex font-bold h-16 shrink-0 items-center">
        {title}
      </div>
      <LinkList
        items={navigation}
        bottomItems={navigationBottom}
      />
    </Sidebar>

    <div className="lg:pl-sb flex flex-col min-h-screen bg-gray-50" ref={footerPortal}>


      <div className="flex-none fixed left-0 lg:left-sb top-0 right-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:pr-8">
        <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <div className="flex flex-1 self-stretch overflow-auto">

            {topPanel}
          </div>

          <div className="flex flex-shrink-0 flex-grow-0 items-center gap-x-4 lg:gap-x-6">
            {search}
            {false && /* hide notification button for now */
              <>
                <button type="button" className="-m-2.5 p-2.5 text-gray-200 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </>
            }
            {userNavigation && <UserNavigationMenu children={userNavigation} />}
          </div>
        </div>
      </div>

      <main className="flex-1 mt-16 relative">
        <StickyWrapper padding={16}>
          <FooterPortalContext.Provider value={footerPortal}>
            {framePadding
              ? <div className="my-4 px-4 sm:px-6 lg:px-8"> {children} </div>
              : children
            }
          </FooterPortalContext.Provider>
        </StickyWrapper>
      </main>
    </div>
  </>
}

export default Layout
