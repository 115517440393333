import { classNames } from 'components/Util';
import React from 'react';
import { NavLink, useInRouterContext } from 'react-router-dom';

type RefType = HTMLAnchorElement & HTMLDivElement
type NavLinkClassName = string | ((args: { isActive: boolean }) => string)

export const LayoutLink = React.forwardRef<RefType, { href: string; className?: NavLinkClassName; children: React.ReactNode }>(({ href, className, children }, ref) => {
  const inRouter = useInRouterContext();

  const fnClassname = typeof className === 'function' ? className : ({ }) => className

  if (!href) {
    return <div ref={ref} className={fnClassname({ isActive: false })} children={children} />
  }

  if (inRouter) {
    return <NavLink
      ref={ref}
      to={{ pathname: href }}
      className={fnClassname}
      children={children}
    />;
  }

  return <a ref={ref} href={href} className={fnClassname({ isActive: false })} children={children} />;
})
