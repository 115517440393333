import React from 'react';

export const LinkList: React.FC<{ items: React.ReactNode; bottomItems?: React.ReactNode }> = ({ items, bottomItems }) => {
  return <nav className="sidebar-nav flex flex-1 flex-col">
    <ul role="list" className="flex flex-1 flex-col gap-y-7">
      <li>
        <ul role="list" className="-mx-2 space-y-1">
          {items}
        </ul>
      </li>
      {bottomItems &&
        <li className="mt-auto">
          <ul role="list" className="-mx-2 space-y-1">
            {bottomItems}
          </ul>
        </li>
      }
    </ul>
  </nav>
}
