import React from 'react'
import { classNames } from 'components/Util'
import { LayoutLink } from './LayoutLink'
import { Fragment } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export const UserNavigationMenu: React.FC<{ children: React.ReactNode }> = ({ children }) =>
  <>
    {/* Separator */}
    <div className="block h-6 w-px bg-gray-200" aria-hidden="true" />

    <Menu as="div" className="relative">
      <MenuButton className="-m-1.5 flex items-center p-1.5 text-gray-400 hover:text-gray-500">
        <span className="sr-only">Open user menu</span>
        <UserIcon
          className="h-6 w-6"
        />
        <span className="hidden lg:flex lg:items-center">
          <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute border-collapse right-0 z-[1100] mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {children}
        </MenuItems>
      </Transition>
    </Menu>
  </>

const UserNavigationClassName = 'block px-3 py-1 text-sm leading-6 text-gray-900'

const UserNavigationLink: React.FC<{ className?: string, href?: string, children: React.ReactNode }> = ({ className, href, children }) =>
  <MenuItem>
    {
      href
        ? <LayoutLink className={classNames(className, UserNavigationClassName, "hover:bg-gray-50")} href={href}>{children}</LayoutLink>
        : <div className={classNames(className, UserNavigationClassName)}>{children}</div>
    }
  </MenuItem>

export default UserNavigationLink
