import React from 'react'

export type ViewportCallback = (event: IntersectionObserverEntry) => void

let ViewportCallbacks = new Map<Element, ViewportCallback>()
let ViewportObserver: IntersectionObserver = null
const ViewportCallbackDispatcher: IntersectionObserverCallback = (evs) => {
  evs.forEach(e => {
    const callback = ViewportCallbacks.get(e.target)
    callback && callback(e)
  })
}

export const observeViewport: (element: Element, callback: ViewportCallback) => (() => void) = (element, callback) => {
  if (ViewportCallbacks.size == 0) {
    // if the callback map is empty, then there won't
    // be an observer setup, let's create one!
    ViewportObserver = new IntersectionObserver(ViewportCallbackDispatcher, { threshold: 1.0 })
  }
  // store the callback and observe the element
  ViewportCallbacks.set(element, callback)
  ViewportObserver.observe(element)

  return () => {
    // this should get called to de-register the observation,
    // let's un-observe the element and forget the callback
    ViewportObserver.unobserve(element)
    ViewportCallbacks.delete(element)

    // if there are no more callbacks, then let's completely
    //disconnect the observer and get rid of it
    if (ViewportCallbacks.size == 0) {
      ViewportObserver.disconnect()
      ViewportObserver = null
    }
  }
}

export function useViewportCallback<E extends Element>(callback: ViewportCallback, condition?: boolean): React.MutableRefObject<E> {
  const ref = React.useRef<E>()

  React.useEffect(() => {
    const element = ref.current

    // if the condition doesn't hold then don't register an observer, the
    // (The previous one will have been automaticaly deallocated by the effect return function)
    if (!condition) { return }

    return observeViewport(element, callback)
  }, [condition])

  return ref
}

export type ViewportRefs = {
  fromXs: boolean
  fromSm: boolean
  fromMd: boolean
  fromLg: boolean
  fromXl: boolean
  from2xl: boolean
  toXs: boolean
  toSm: boolean
  toMd: boolean
  toLg: boolean
  toXl: boolean
  to2xl: boolean
}
export type ViewportRef = keyof ViewportRefs
type ViewportFunction = () => ViewportRefs

const buildViewport = (width: number) => ({
  fromXs: width >= 450,
  fromSm: width >= 640,
  fromMd: width >= 768,
  fromLg: width >= 1024,
  fromXl: width >= 1280,
  from2xl: width >= 1536,
  toXs: width < 450,
  toSm: width < 640,
  toMd: width < 768,
  toLg: width < 1024,
  toXl: width < 1280,
  to2xl: width < 1536,
})

export const ViewportContext = React.createContext(buildViewport(window.innerWidth))
export const useViewport: ViewportFunction = () => React.useContext(ViewportContext)

export const ViewportWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [value, setValue] = React.useState(() => buildViewport(window.innerWidth))

  const setWidth = React.useCallback((width: number) => {
    const newValue = buildViewport(window.innerWidth)
    const newValueString = Object.keys(newValue).filter((k) => !!newValue[k]).join(",")
    const valueString = Object.keys(value).filter((k) => !!value[k]).join(",")
    if (newValueString != valueString) {
      setValue(newValue)
    }
  }, [value, setValue])

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [setWidth]);


  return <ViewportContext.Provider value={value}>
    {children}
  </ViewportContext.Provider>
}

