import { classNames } from '.'
import React from 'react'
import Spinny from './Spinny'
import { FormIdContext } from '@form/FormLayout/Stacked'

// https://tailwindui.com/components/application-ui/elements/buttons

type ButtonRole = "primary" | "secondary" | "danger" | "colour-amber"
type ButtonSize = "xs" | "sm" | "md" | "lg" | "xl"

export type ButtonProps = {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"]
  role?: ButtonRole
  disabled?: boolean
  className?: string
  id?: string
  size?: ButtonSize
  style?: React.CSSProperties
  busyText?: React.ReactNode
  tabIndex?: number
  onClick?: () => (any | Promise<any>)
  children: React.ReactNode
}

const ButtonClasses = "font-semibold shadow-sm inline-flex items-center justify-center"
const ButtonColourClasses = (role: ButtonRole) => {
  switch (role) {
    case "danger":
      return "bg-red-600 text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
    case "primary":
      return "bg-green-600 text-white hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
    case "secondary":
      return "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    case "colour-amber":
      return "bg-amber-600 text-black"
    default:
      return "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600"
  }
}

const ButtonSizeClasses = (size: ButtonSize) => {
  switch (size) {
    case "xs": return "rounded    px-2   py-1  gap-x-1   text-xs"
    case "sm": return "rounded    px-2   py-1  gap-x-1.5 text-sm"
    case "lg": return "rounded-md px-3   py-2  gap-x-2   text-sm"
    case "xl": return "rounded-md px-3.5 py-2.5 gap-x-2  text-sm"

    case "md":
    default:
      return "rounded-md px-2.5 py-1.5 text-sm gap-x-1.5"
  }
}

const DisabledButtonClasses = "opacity-40"
const BusyButtonClasses = "opacity-90"

const DefaultBusy: React.FunctionComponent<{ busyText?: React.ReactNode, children: React.ReactNode }> = ({ children, busyText }) => <>
  {busyText || <><Spinny className="h-4 mr-2" />{children}</>}
</>

export const Button: React.FunctionComponent<ButtonProps> = ({ children, tabIndex, type, busyText, id, disabled, style, className, role, size, onClick }) => {
  const [busy, setBusy] = React.useState<boolean>(false)

  const clickHandler = React.useCallback(async (ev) => {
    if (onClick) {
      ev.preventDefault()
      setBusy(true)
      await onClick()
      setBusy(false)
    }
  }, [setBusy, onClick])

  // set the form ref if we're inside the form context
  const formId = React.useContext(FormIdContext)

  return <button
    tabIndex={tabIndex}
    form={formId}
    disabled={disabled || busy}
    className={classNames(ButtonClasses, ButtonColourClasses(role), ButtonSizeClasses(size), className, disabled && DisabledButtonClasses, busy && BusyButtonClasses)}
    style={style}
    id={id}
    type={type || "button"}
    children={busy
      ? <DefaultBusy busyText={busyText} children={children} />
      : children
    }
    onClick={clickHandler}
  />
}

export const DangerButton: React.FunctionComponent<ButtonProps> = (props) => <Button {...props} role="danger" />
export const PrimaryButton: React.FunctionComponent<ButtonProps> = (props) => <Button {...props} role="primary" />
export const SecondaryButton: React.FunctionComponent<ButtonProps> = (props) => <Button {...props} role="secondary" />
