import React from 'react'
import Rollbar from "rollbar";

import { getEnvironment } from 'components/Util';

const env = getEnvironment()

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: "70c293cc33604184a6c45a5a0bfc6f86",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: env
  },
  ignoredMessages: [
    /The width\(0\) and height\(0\) of chart should be greater than 0,/
  ],
  checkIgnore: function (isUncaught, args, payload) {
    if (env == "development" || env == "test") {
      return true
    } else {
      return false
    }
  }
}

Rollbar.init(rollbarConfig)

import { ErrorDialog } from 'components/Util/Dialog/ErrorDialog';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { ServerError } from './GraphQL';
import { ServerErrorInspector } from './ServerErrorInspector';

export const UnexpectedErrorDialog = ({ error }) => {
  const [expanded, setExpanded] = React.useState(false)
  React.useEffect(() => {
    console.error(`UnexpectedErrorDialog: ${JSON.stringify(error)}`)
  }, [error])

  return <ErrorDialog
    open={true}
    fatal={true}
    button="Reload"
    onClose={() => window.location.reload()}
    detail={env == "development" && error instanceof ServerError &&
      <ServerErrorInspector error={error} />
    }
  >
    <div className="mb-2">
      An unexpected error has occurred which has been reported. You can reload the page to retry.
    </div>

  </ErrorDialog>
}

const ErrorWrapper: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => <>
  <Provider config={rollbarConfig}>
    <ErrorBoundary fallbackUI={({ error, resetError }) => (
      <UnexpectedErrorDialog error={error} />
    )}>
      {children}
    </ErrorBoundary>
  </Provider>
</>

export default ErrorWrapper