import React, { PropsWithChildren, Suspense, useLayoutEffect } from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import { classNames } from '..';
import Spinny from '../Spinny';
import { XMarkIcon } from '@heroicons/react/24/outline';


const BottomTransition = ({ children }) =>
  <Transition.Child
    as={React.Fragment}
    enter="ease-out duration-3000"
    enterFrom="opacity-0 translate-y-full"
    enterTo="opacity-100 translate-y-0"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 translate-y-full"
    children={children}
  />


const FloatingTransition = ({ children }) =>
  <Transition.Child
    as={React.Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enterTo="opacity-100 translate-y-0 sm:scale-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    children={children}
  />

const AttachmentProps = {
  floating: {
    transition: FloatingTransition,
    panel: "rounded-lg mb-5 sm:mb-0 max-w-sm sm:max-w-md w-full mx-2",
    container: "flex flex-row items-end sm:items-center justify-center",

  },
  bottom: {
    transition: BottomTransition,
    panel: "fixed right-2 bottom-0 left-2 rounded-t-lg ",
    container: "",
  },
}

export type DialogProps = React.PropsWithChildren<{
  attachment?: keyof typeof AttachmentProps
  open?: boolean
  className?: string
  onClose?: () => void
}>

export const DialogCloseContext = React.createContext(() => { })

require("./overflowy.scss")

const Dialog: React.FunctionComponent<DialogProps> = ({ open, attachment, className, onClose, children }) => {
  const Attachment = AttachmentProps[attachment || "floating"]

  return <Transition show={open} as={React.Fragment}>
    <HeadlessDialog as="div" static className={classNames(Attachment.container, "fixed z-[1500] inset-0 overflow-y-auto")} onClose={onClose}>

      <DialogCloseContext.Provider value={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" onClick={(ev) => { ev.preventDefault(); onClose() }} />
        </Transition.Child>

        <Attachment.transition>
          <HeadlessDialog.Panel className={classNames(className, Attachment.panel, "flex flex-col h-auto max-h-[95%] bg-white text-left overflow-hidden transform transition-all")}>
            <Suspense fallback={
              <div className="p-6 flex flex-row items-center justify-center">
                <Spinny className="w-8 h-8" />
                <button className="grow-0 text-xl sr-only" onClick={onClose}>
                  <XMarkIcon className="w-6" />
                </button>
              </div>
            }>
              {children}
            </Suspense>
          </HeadlessDialog.Panel>
        </Attachment.transition>
      </DialogCloseContext.Provider>
    </HeadlessDialog>
  </Transition>
}

const StickyHeader = (props: PropsWithChildren<{ className?: string }>) =>
  <HeadlessDialog.Title className={classNames("sticky top-0 shrink-0 dialog-header", props.className)}>
    {props.children}
  </HeadlessDialog.Title>

const StickyFooter = (props: PropsWithChildren<{ className?: string }>) =>
  <div className={classNames("sticky bottom-0 shrink-0 bg-white dialog-footer", props.className)}>
    {props.children}
  </div>

export const ScrollContext = React.createContext<number>(null)

const ScrollBody = (props: PropsWithChildren<{ className?: string }>) => {
  const elementRef = React.useRef<HTMLDivElement>(null)
  const [scrollTop, setScrollTop] = React.useState<number>(0)

  useLayoutEffect(() => {
    if (elementRef.current) {
      elementRef.current.style.setProperty('--scrollTop', `${scrollTop}px`)
    }
  }, [scrollTop])

  return <div className={classNames("overflowy relative", props.className)} onScroll={(ev) => setScrollTop(elementRef.current.scrollTop)} ref={elementRef}>
    {props.children}
  </div>
}

export default Object.assign(Dialog, {
  Title: HeadlessDialog.Title,
  StickyHeader, StickyFooter, ScrollBody
})
