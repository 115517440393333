import React, { createElement } from 'react'
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client'

import GraphQL, { FetchOptions } from './GraphQL'
import ErrorHandling from './ErrorHandling'
import Suspense from './Suspense'

import { ViewportWrapper } from 'components/Util/Viewport';

import Bootable from './Bootable'

import { init as initApm } from '@elastic/apm-rum'

type BootOptions = {
  graphql?: FetchOptions
}

export function wrapper<P>(Component: React.FunctionComponent<P>, opts: BootOptions): React.FunctionComponent<P> {
  return (props) => <React.StrictMode>
    <GraphQL {...opts.graphql}>
      <Suspense>
        <ErrorHandling>
          <ViewportWrapper>
            <Component {...props} />
          </ViewportWrapper>
        </ErrorHandling>
      </Suspense>
    </GraphQL>
  </React.StrictMode>
}

export const NestedHtml: React.FunctionComponent<{ html: string; className?: string }> = ({ html, className }) => {
  const container = React.useRef<HTMLDivElement>();
  const [boundComponents, setBoundComponents] = React.useState<BoundComponent<any>[]>([])

  React.useEffect(() => {
    container.current.innerHTML = html;
    findAllBootableComponents(container.current, (component) => {
      setBoundComponents(portals => ([...portals, component]))
    })

    return () => setBoundComponents([])
  }, [])

  return <>
    <div className={className} ref={container} />
    {boundComponents.map(({ component, props, element }) =>
      createPortal(createElement(component, props), element)
    )}
  </>
};


type BoundComponent<T> = { element: HTMLElement, component: React.FunctionComponent<T>, props: T }
function buildComponent<T>(element: HTMLElement): BoundComponent<T> | null {
  const componentName = element.dataset['component']
  delete element.dataset['component'];
  element.classList.remove("unbooted")

  const component = Bootable[componentName]
  if (!component) {
    console.warn(`Cannot find component '${componentName}'`)
    return null
  }
  var props = element.dataset['props'] ? JSON.parse(element.dataset['props']) : {} as T

  props['children'] = <NestedHtml html={element.innerHTML} />
  element.innerHTML = ""

  delete element.dataset['props'];
  return { element, component: React.lazy(component), props }
}

type BootableContainer = Document | HTMLElement
function findAllBootableComponents(container: BootableContainer, fn: <T>(args: BoundComponent<T>) => void) {
  let element: HTMLElement

  // continually re-query to avoid booting components nested in other unbooted components!
  while (element = container.querySelector(`[data-component]`)) {
    const output = buildComponent(element)
    if (output?.component) {
      fn(output)
    }
  }
}

export function performBoot(container: BootableContainer, opts: BootOptions) {
  findAllBootableComponents(container, ({ component, props, element }) => {
    createRoot(element).render(createElement(wrapper(component, opts), props))
  })
}

export function boot(document: Document, opts: BootOptions = {}) {
  document.addEventListener('DOMContentLoaded', () => {
    performBoot(document, opts)
  })

  initApm({
    serviceName: 'hatches-up-js',
    serverUrl: 'https://apm.svc.tdhserve.net/',
    serviceVersion: '',
    environment: 'production'
  })
}
