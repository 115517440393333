import React from 'react';
import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'
import Dialog, { DialogProps } from '.'
import { Button } from '../Button';
import { ErrorBoundary } from '@rollbar/react';

const FallbackUI = ({ error }) => <>Error in ErrorDialog</>


export const ErrorDialog: React.FunctionComponent<DialogProps & { fatal?: boolean, title?: string, detail?: React.ReactNode, button: string }> = (props) => {
  const { open, fatal, title, children, onClose, button } = props

  const colourClass = fatal ? {
    text: "text-red-600", background: "bg-red-100"
  } : {
    text: "text-amber-600", background: "bg-amber-100"
  }

  return <ErrorBoundary fallbackUI={FallbackUI}>
    <Dialog open={open} onClose={onClose} className="p-3">
      <Dialog.StickyHeader>
        <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${colourClass.background}`}>
          <ExclaimationCircleIcon className={`h-6 w-6 ${colourClass.text}`} aria-hidden="true" />
        </div>

        <div className="mt-3 text-center sm:mt-5">
          {title &&
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
              {title}
            </Dialog.Title>
          }
          {children &&
            <div className="mt-2 text-sm text-gray-500">
              {children}
            </div>
          }
        </div>
      </Dialog.StickyHeader>
      {props.detail &&
        <Dialog.ScrollBody>
          {props.detail}
        </Dialog.ScrollBody>
      }
      <Dialog.StickyFooter>
        <Button
          role="primary"
          className="w-full"
          onClick={onClose}
        >
          {button}
        </Button>
      </Dialog.StickyFooter>
    </Dialog>
  </ErrorBoundary>
};
