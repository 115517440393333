import React from 'react'

import { RelayEnvironmentProvider } from 'react-relay'
import { Environment, FetchFunction, Network, Observable, RecordSource, Store, SubscribeFunction } from 'relay-runtime'
import { getCsrfToken } from 'components/Util'

export class ServerError extends Error {
  response: Response
  errors: {
    message: string
    backtrace?: string[]
  }[]

  constructor(response: Response) {
    super("Server Error, packed response")
    this.response = response
  }

  async unpack() {
    try {
      const parsed = await this.response.json()
      this.errors = parsed.errors
      this.message = `${this.errors.length} errors returned from the server`
    } catch {
      this.message = "Couldn't unpack JSON error response from the server"
    }
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super("Unauthorized")
  }
}

export type FetchOptions = {
  minAuth?: string,
}
type FetchFactory = (
  options: FetchOptions,
) => FetchFunction

const fetchFactory: FetchFactory = (options) => async ({ id, text, name }, variables, _cacheConfig, uploadables) => {
  const formData = new FormData()

  if (id) { formData.append('doc_id', id) }
  if (name) { formData.append('operation_name', name) }
  if (text) { formData.append('query', text) }
  if (variables) { formData.append('variables', JSON.stringify(variables)) }
  if (options.minAuth) { formData.append('min_auth', options.minAuth) }

  if (uploadables) {
    Object.keys(uploadables).forEach((key) => {
      formData.append(`uploadables[${key}]`, uploadables[key])
    })
  }

  const response = await fetch("/graphql", {
    method: "POST",
    headers: { 'X-CSRF-Token': getCsrfToken(), },
    body: formData,
    credentials: "same-origin", // ensure cookies DTRT
  });

  if (response.status == 401) {
    throw new UnauthorizedError()
  }

  if (response.status / 100 != 2) {
    const error = new ServerError(response)
    await error.unpack()
    throw error
  }

  const data = await response.json();
  if (data.errors?.length && data.errors.length > 0) {
    console.error(`GraphQL Errors: ${data.errors.map(e => e.message).join(", ")}`)
  }
  return data
}

import { createConsumer } from "@rails/actioncable"

const consumer = createConsumer()

const subscribeFn: SubscribeFunction = (request, variables, cacheConfig, observer) =>
  Observable.create((sink) => {
    let channel = consumer.subscriptions.create({ channel: "GraphqlChannel" }, {
      connected() {
        this.perform("execute", {
          operationName: request.name,
          doc_id: request.id,
          query: request.text,
          variables
        })
      },
      received(data) {
        if (data['result'] && data['result']['data'] && Object.keys(data['result']['data']).length > 0) {
          sink.next(data['result'])
        }
        if (data['more'] == false) {
          sink.complete()
        }
      },
      disconnected() {
        sink.complete()
      },
    })
    let subscription = {
      unsubscribe: () => {
        subscription.closed = true
        channel.unsubscribe()
      },
      closed: false
    }

    return subscription
  })

const WrapGraphql: React.FunctionComponent<FetchOptions & { children: React.ReactNode }> = (props) => {
  const environment = React.useMemo(() => {
    return new Environment({
      network: Network.create(fetchFactory(props), subscribeFn),
      store: new Store(new RecordSource())
    })
  }, [props])

  return <RelayEnvironmentProvider environment={environment}>
    {props.children}
  </RelayEnvironmentProvider>
}

export default WrapGraphql
