import React from 'react';
import { ServerError } from './GraphQL';

export const ServerErrorInspector: React.FC<{ error: ServerError; }> = ({ error }) => {
  const [showBacktrace, setShowBacktrace] = React.useState<boolean>(false);

  return <>
    <div className="pt-1 mt-1 flex flex-col items-start border-gray-200 border-t text-left">
      {error.errors.map(e => <>
        <div className="font-mono">
          {e.message}
        </div>
        <a onClick={() => setShowBacktrace(v => !v)} className="text-xs">
          {showBacktrace ? "Hide backtrace" : "Show backtrace"}
        </a>
        {showBacktrace &&
          <div className="text-xs overflow-scroll whitespace-pre">
            {e.backtrace.join("\n")}
          </div>
        }
      </>)}
    </div>
  </>;
};
