import React from "react"

type ChildAction<T> = { type: "register", id: string, event: T } | { type: "unregister", id: string }
type ChildDispatchFn<T> = (props: ChildAction<T>) => void

type GatheredChild<T> = React.FC<T> & { Context: React.Context<ChildDispatchFn<T>> }

export function createGatheredChild<T>(): GatheredChild<T> {
  const Context = React.createContext<ChildDispatchFn<T>>(null)

  const Component = (props: T) => {
    const dispatch = React.useContext(Context)
    const id = React.useId()
    React.useEffect(() => {
      if (dispatch) {
        dispatch({ type: "register", id, event: props })
        return () => dispatch({ type: "unregister", id })
      }
    }, [dispatch, ...Object.values(props)])

    return <></>
  }

  return Object.assign(Component, { Context })
}

type GathererWrapper = React.FC<{ children: React.ReactNode }>
type GatherState<T> = { id: string, value: T }

export function useGatheredChildren<T>(Gatherer: GatheredChild<T>): [T[], GathererWrapper] {
  const [state, dispatch] = React.useReducer((state: GatherState<T>[], action: ChildAction<T>): GatherState<T>[] => {
    if (action.type == 'register') {
      state = [...state, { id: action.id, value: action.event }]
    } else if (action.type == 'unregister') {
      state = state.filter((v) => v.id != action.id)
    }
    return state
  }, [])

  const Wrapper: GathererWrapper = React.useCallback(({ children }) => {
    const parentDispatch = React.useContext(Gatherer.Context)
    const childDispatch = React.useCallback<typeof dispatch>((value) => {
      dispatch(value)
      if (parentDispatch) { parentDispatch(value) }
    }, [dispatch, parentDispatch])

    return <Gatherer.Context.Provider value={childDispatch}>
      {children}
    </Gatherer.Context.Provider>
  }, [Gatherer, dispatch])

  const children = React.useMemo(() => state.map(s => s.value), [state])

  return [children, Wrapper]
}

