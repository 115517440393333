import React, {PropsWithChildren, Suspense} from 'react'

const SuspenseFallback = ({}) => <div>...</div>

const SuspenseWrapper: React.FunctionComponent<PropsWithChildren<{}>> = ({children}) => <>
  <Suspense fallback={<SuspenseFallback />}>
    {children}
  </Suspense>
</>

export default SuspenseWrapper