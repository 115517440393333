import React, { useId } from 'react'
import { classNames } from 'components/Util'

import { DataContext, useValue } from './Data'
import { InputAdapter } from './InputAdapters'
import { createGatheredChild, useGatheredChildren } from 'components/Util/GatheredChildren'

export const InputIdContext = React.createContext<string | null>(null)

type InputSpec = { id: string, field: DataContext<any>, component: React.FC<any> }

type ComponentWithInput<P> = React.FC<P & { id: string, input: InputSpec }>
type ComponentWithInputs<P> = React.FC<P & { inputs: InputSpec[] }>

export const NestedInput = createGatheredChild<InputSpec>()

// Component wraps multiple inputs
export function withInputs<T>(C: ComponentWithInputs<T>): React.FunctionComponent<T> {
  return (props) => {
    const [inputs, GatherChildren] = useGatheredChildren(NestedInput)

    return <GatherChildren>
      <C inputs={inputs} {...props} />
    </GatherChildren>
  }
}

// Component wraps _exactly_ one input and can access some shared state
export function withInput<T>(C: ComponentWithInput<T>): React.FunctionComponent<T> {
  return withInputs<T>(({ inputs, ...props }) => {
    const input = inputs[0]
    const id = useId()
    React.useEffect(() => {
      if (inputs.length > 1) {
        console.warn("Multiple inputs for withInput component, using the first")
      }
    }, [inputs])

    return <InputIdContext.Provider value={id}>
      <C id={id} input={input} {...props as T} />
    </InputIdContext.Provider>
  })
}

export const Label: React.FunctionComponent<{ hidden?: boolean, className?: string, children: React.ReactNode }> = ({ hidden, className, children }) =>
  <label htmlFor={React.useContext(InputIdContext)} className={hidden ? "sr-only" : className}>
    {children}
  </label>

export const InputClassNameContext = React.createContext<string>("")

// this is the function component that is actually used by the app, and incorporates the field object
type InputFunctionComponentProps<Type, Extra> = Extra & {
  field: DataContext<Type>
  className?: string
}

type GenericInputFunctionComponent<Type, Extra> = (props: React.PropsWithChildren<InputFunctionComponentProps<Type, Extra>>) => React.ReactElement
type MakeInputFunctionComponent = <Type, Extra>(adapter: InputAdapter<Type, Extra>) => GenericInputFunctionComponent<Type, Extra>

export const makeInputAdapter: MakeInputFunctionComponent = <Type, Extra>(Adapter: InputAdapter<Type, Extra>) => {
  return (props: InputFunctionComponentProps<Type, Extra>) => {

    const [value, setValue] = useValue(props.field)

    const id = React.useContext(InputIdContext) || useId()
    const inheritedClassName = React.useContext(InputClassNameContext)

    return <>
      <NestedInput id={id} field={props.field} component={Adapter} />
      <Adapter
        {...props}
        id={id}
        className={classNames(inheritedClassName, props.className)}
        value={value}
        onChange={setValue}
      />
    </>
  }
}
