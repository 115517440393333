import React, { useCallback, useMemo, useState } from 'react'

require('./Util.scss')

export const extractMeta: (name: string, defValue: string) => () => string = (name, defValue) => () => document.querySelector(`meta[name="${name}"]`)?.attributes['content']?.value || defValue
export const getCsrfToken: () => string = extractMeta("csrf-token", null)
export const getEnvironment: () => string = extractMeta("environment", "production")

export type RetainHandle = () => void;
export type RetainFn = {
  <T>(fn: () => Promise<T>): Promise<T>
  (): (RetainHandle)
}

export function groupBy<T>(iterable: Iterable<T>, fn: (item: T) => string | number) {
  return [...iterable].reduce<Record<string, T[]>>((groups, curr) => {
    const key = fn(curr);
    const group = groups[key] ?? [];
    group.push(curr);
    return { ...groups, [key]: group };
  }, {});
}

export function useRetain(): [boolean, RetainFn] {
  const [state, setState] = useState<number>(0)

  const genRetainHandle = useCallback(() => {
    let released = false
    setState((state) => state + 1)
    return () => {
      if (!released) {
        released = true
        setState((state) => state - 1)
      }
    }
  }, [])

  const retain: RetainFn = useCallback<RetainFn>((fn = null) => {
    const handle: RetainHandle = genRetainHandle()

    return (typeof fn === 'function')
      ? fn().finally(handle)
      : handle
  }, [])

  return [state > 0, retain]
}

export type PropsOf<V> = V extends React.FunctionComponent<infer P> ? P : never

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}
const StickyContext = React.createContext(0)
export const useSticky: () => React.CSSProperties = () => {
  const top = React.useContext(StickyContext) || 0
  return {
    position: "sticky",
    top: `${top * 0.25}rem`
  }
}
export const StickyWrapper: React.FC<{ padding: number, children: React.ReactNode }> = ({ padding, children }) => {
  const current = React.useContext(StickyContext) || 0
  return <StickyContext.Provider value={padding + current} children={children} />
}

import { useLocation } from 'react-router-dom'

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useQueryState(key: string, initialState: string | (() => string)): [string, React.Dispatch<React.SetStateAction<string>>] {
  const value = (history.state && history.state[key]) || ((typeof initialState == 'function') ? initialState() : initialState)
  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const setValue = React.useCallback<React.Dispatch<React.SetStateAction<string>>>((arg) => {
    const newValue = (typeof arg == 'function') ? arg(value) : arg
    history.replaceState({ ...(history.state || {}), [key]: newValue }, "")
    forceUpdate()
  }, [value])

  return [value, setValue]
}
