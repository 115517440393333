import { ConditionalMapFn, DataContext, DataError, ElementOf, GetterFn, MapFn, ObjectProxy, SetterFn } from "."
import { NodeValue } from "./NodeValue"
import { Observable } from "./Observable"
import { TreeStructure } from "./TreeStructure"

type NodeFlavour = null | 'scalar' | 'object' | 'array'

type ConstructorFn = new <C>(args: NodeConstructorArgs<C, NodeWrapper<any>>) => NodeWrapper<any>

export type NodeConstructorArgs<T, K extends TreeStructure<any>> = { key?: string, childConstructor?: ConstructorFn } & (
  { type: 'root', get: GetterFn<T> } |
  { type: 'related', owner: NodeWrapper<T>, observable: Observable, default?: T } |
  { type: 'nested', parent: K, get: GetterFn<T>, set: SetterFn<T> })

export class NodeWrapper<T> extends NodeValue<T, NodeWrapper<any>> implements DataContext<T> {
  args: NodeConstructorArgs<T, NodeWrapper<any>>

  flavour: NodeFlavour
  observable: Observable

  public setValue(fn: (old: T) => T) {
    this.set((old) =>
      this.notifyAll(() =>
        this.trackDirty(old, fn(old))
      )
    )
  }

  default(value: T): DataContext<T> {
    return new this.args.childConstructor({ type: 'related', owner: this, observable: this.observable, default: value })
  }

  constructor(args: GetterFn<T> | NodeConstructorArgs<T, NodeWrapper<any>>) {
    if (typeof args === 'function') {
      args = { type: 'root', get: args }
    }

    args.childConstructor ||= NodeWrapper
    super(args)
    this.args = args
    this.observable = args.type == 'related' ? args.observable : new Observable()
    this.flavour = 'scalar'
  }

  become(flavour: NodeFlavour) {
    if (this.flavour == flavour) return

    // only allow a change if we're currently a scalar
    if (this.flavour != 'scalar') {
      throw (`Node is already ${this.flavour} cannot become ${flavour}`)
    }

    // we can't change from scalar -> object (for now) if we're dirty!
    if (this.isDirty()) { throw ("Can't change flavour if dirty") }

    this.flavour = flavour
  }

  ///* Scalar Value Accessors *////
  public get value() {
    return this.get()
  }

  public set value(newValue: T) {
    this.setValue(() => newValue)
  }

  ///*** Object ***////
  objectProxy: ObjectProxy<T>
  public get for(): ObjectProxy<T> {
    this.become('object')

    const parent = this
    this.objectProxy ||= new Proxy<ObjectProxy<T>>({}, {
      get(target, key, receiver) {
        return parent.treeChild(key, () => new parent.args.childConstructor(parent.childArgs(parent, key as keyof T)))
      }
    })

    return this.objectProxy
  }

  getArrayChild(index: number): NodeWrapper<ElementOf<T>> {
    this.become('array')
    const parent = this
    return this.treeChild(index, () => new parent.args.childConstructor(parent.arrayChildArgs(parent, index)))
  }

  // include insertions, include deletions, order: (original | current)
  map: ConditionalMapFn<T> = (fn) => {
    this.become('array')

    const value = this.value

    if (!Array.isArray(value)) {
      throw ("cannot map a non-array")
    }

    return value.map((v, i) => fn(this.getArrayChild(i), i))
  }

  ///*** Observations ***///
  // need a central mechanism to distribute updates rather than
  // dynamically building a list on each invocation
  notifyAll: (<R>(update: () => R) => R) = (update) =>
    [this, ...this.ancestors, ...this.descendents].reduce((fn, next) => () => next.observable.notify(fn), update)()

  /// ***  Error Tracking *** ///
  _errors: DataError[] = []
  setErrors(errors: readonly DataError[]) {
    if (this.ancestors.length > 0) {
      throw ("Set errors on the root node")
    }
    this.notifyAll(() => {
      this._errors = [...errors]
    })
  }

  get errors(): DataError[] {
    const keyPath = this.keyPath

    return this.isRoot ? this._errors : this.root.errors.filter(e => {

      const match = keyPath.find((key, index) => {
        return e.attribute[index] != key
      }) == null

      return match
    })
  }

  /// *** Dirty State Tracking ***///
  changeStack: T[] = []

  trackDirty(oldValue: T, newValue: T): T {
    if (this.flavour == 'scalar') {
      this.changeStack.unshift(oldValue)
    }
    return newValue
  }

  isDirty(): boolean {
    switch (this.flavour) {
      case 'array': return this.changeStack.length > 0
      case 'object': return this.children.find(c => c.isDirty()) != null
      case 'scalar': return this.changeStack.length > 0
    }
  }

  getDirtyValue(): Partial<T> {
    switch (this.flavour) {
      case 'array':
        return this.value
      case 'object':
        const children = this.children.map(c => c.key).reduce((out, key) => {
          const value = this.treeChild(key).getDirtyValue()
          if (value === undefined) {
            return out
          } else {
            return { ...out, [key]: value }
          }
        }, {})
        return children
      case 'scalar':
        return this.changeStack.length > 0 ? this.value : undefined
    }
  }

  clearDirty() {
    const node = this
    this.notifyAll(() => {
      this.changeStack = []

      if (this.flavour == 'object') {
        node.descendents.forEach(c => c.clearDirty())
      }
    })
  }

  childArgs<Key extends keyof T>(parent: NodeWrapper<any>, key: Key): NodeConstructorArgs<T[Key], NodeWrapper<any>> {
    return {
      type: 'nested',
      key: String(key),
      parent: parent,
      get: () => {
        const value = this.get()
        if (value !== undefined && value[key] !== undefined) {
          return value[key]
        }
        return undefined // if the parent data or the inner key is missing, return undefined!
      },
      set: (fn) => {
        this.set((old) => ({ ...old, [key]: fn(old ? old[key] : undefined) }))
      }
    }
  }

  arrayChildArgs(parent: NodeWrapper<any>, index: number): NodeConstructorArgs<ElementOf<T>, NodeWrapper<any>> {
    return {
      type: 'nested',
      key: String(index),
      parent: parent,
      get: () => this.get()[index],
      set: (fn) => this.set((old) => {
        if (!Array.isArray(old)) { throw ("not an array in an array update fn") }
        const newArray = [...old]
        newArray.splice(index, 1, fn(old[index]))
        return newArray as T
      })
    }
  }
}
