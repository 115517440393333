export type SubscriptionHandle = {
  invalidate: () => void
}
type SubscriptionFn = () => void

export class Observable {
  baseFn?: SubscriptionFn
  subscriptions = new Set<SubscriptionFn>()

  constructor(base?: SubscriptionFn) {
    this.baseFn = base
  }

  notify: (<T>(around?: () => T) => T) = (around) => {
    let ret = null
    if (around) { ret = around() }
    if (this.baseFn) this.baseFn()
    this.subscriptions.forEach(s => s())
    return ret
  }

  subscribe(fn: SubscriptionFn): SubscriptionHandle {
    this.subscriptions.add(fn)
    return { invalidate: () => this.subscriptions.delete(fn) }
  }
}

