import React from 'react'
import { RouterProvider, createBrowserRouter, useInRouterContext, useMatches, generatePath, RouteObject as InternalRouteObject } from 'react-router-dom'

export type HandleType = {
  crumb?: (data: any) => React.ReactNode,
  framePadding?: boolean,
  name?: string
}

export type RouteObject = {
  path?: string,
  Component?: React.FC<any>,
  element?: React.ReactNode,
  errorElement?: React.ReactNode,
  handle?: HandleType,
  children?: RouteObject[],
}

interface Mountable {
  mount(): RouteObject
}

class WrappedRouteObject implements Mountable {
  route: RouteObject
  constructor(route: RouteObject) {
    this.route = route
  }
  mount(): RouteObject {
    return this.route
  }
}

type WrapParam = (RouteObject | RouteObject[])

class Routing {
  static wrap(arg: WrapParam): Mountable {
    if (Array.isArray(arg)) {
      return new WrappedRouteObject({ children: arg })
    } else {
      return new WrappedRouteObject(arg)
    }
  }

  static catch(Component: React.FunctionComponent, arg: WrapParam): Mountable {
    return new WrappedRouteObject({
      errorElement: <Component />,
      ...Routing.wrap(arg).mount(),
    })
  }
}


const defaultMetadata: HandleType = {
  framePadding: true
}

export const useRouteMetadata: () => HandleType = () => {
  if (useInRouterContext()) {
    let matches = useMatches()
    return { ...defaultMetadata, ...matches[matches.length - 1]?.handle as HandleType }
  }
  return defaultMetadata
}

function mapRouteNames(routes: RouteObject[], parents: RouteObject[] = []): { [name: string]: string } {
  let out = {}

  routes.forEach((route) => {
    if (route.children) {
      out = { ...out, ...mapRouteNames(route.children, [...parents, route]) }
    }

    const name: string = route.handle?.name
    if (name) {
      const parent = [...parents, route].map(p => p.path).join("/")
      out = { ...out, [name]: parent }
    }
  })

  return out
}

const NamedRouteContext = React.createContext<{ [name: string]: string }>({})

type RouteName = string | [string, { [param: string]: string }]

export const useNamedRoutes: () => (name: RouteName, query?: string | string[][] | Record<string, string> | URLSearchParams) => string = () => {
  const map = React.useContext(NamedRouteContext)
  return React.useCallback((name, query) => {
    let paramsName: string, params: Object

    if (typeof name === 'string') {
      paramsName = name
      params = {}
    } else {
      [paramsName, params] = name
    }

    const patternPath = map[paramsName]
    if (patternPath == null) {
      throw `No route named ${paramsName}`
    }

    const path = generatePath(patternPath, params)

    if (query) {
      if (!(query instanceof URLSearchParams)) {
        query = new URLSearchParams(query)
      }
      return `${path}?${query.toString()}`
    }
    return path
  }, [])
}

const Provider: React.FC<{ root: Mountable }> = ({ root }) => {
  const mounted = React.useMemo(() => root.mount(), [])
  const dashboardRouter = React.useMemo(() => createBrowserRouter([mounted]), [])
  const RouteMap = React.useMemo(() => mapRouteNames([mounted]), [])

  return <NamedRouteContext.Provider value={RouteMap}>
    <RouterProvider router={dashboardRouter} />
  </NamedRouteContext.Provider>
}

export default Object.assign(Routing, { Provider })
