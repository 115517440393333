import { GetterFn, SetterFn } from ".";
import { NodeConstructorArgs } from "./NodeWrapper";
import { TreeStructure } from "./TreeStructure";

export class NodeValue<T, K extends TreeStructure<any>> extends TreeStructure<K> {

  protected state?: T
  protected get: GetterFn<T>
  protected set: SetterFn<T>

  constructor(args: NodeConstructorArgs<any, K>) {
    super(args)

    switch (args.type) {
      case 'root':
        this.state = args.get()
        this.get = () => this.state
        this.set = (fn) => this.state = fn(this.state)
        break;

      case 'nested':
        this.get = args.get
        this.set = args.set
        break;

      case 'related':
        this.get = () => {
          const value = args.owner.get()
          if (value === undefined || value === null) {
            return args.default
          }
          return value
        }
        this.set = (fn) => args.owner.setValue(fn)
        break;
    }

  }
}


