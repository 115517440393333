import React from 'react'
import { useMemo } from 'react'
import { DataContext, DataError, SetterFn } from '.'
import { NodeWrapper } from './NodeWrapper'
import { Observable } from './Observable'

export function useData<T>(fn: () => T): DataContext<T> {
  return useMemo(() => new NodeWrapper(fn), [])
}

export function useValue<T>(context: DataContext<T>): [T, SetterFn<T>] {
  const setCallback = React.useCallback((fn: (v: T) => T) => {
    context.value = fn(context.value)
  }, [])
  const value = observableMemo(context.observable, () => context.value)

  return [
    value,
    setCallback
  ]
}

type DataContextArrayHandle<Element> = {
  map: <R>(fn: (e: DataContext<Element>, k: number) => R) => R[]
}
export function useArrayValue<T>(context: DataContext<T>): DataContextArrayHandle<T extends (infer Base)[] ? Base : never> {
  watchObservable(context.observable)

  return {
    map: (fn) => context.map(fn)
  }
}

// return the number to indicate an invalidation (for useMemo, et.al)
export function watchObservable(observer: Observable): number {
  const [v, dispatch] = React.useReducer((v) => v + 1, 0)
  React.useEffect(() => observer?.subscribe(dispatch).invalidate, [observer])
  return v
}

export const observableMemo = <T,>(observable: Observable, fn: () => T): T =>
  React.useMemo(fn, [watchObservable(observable)])

export const useDirtyValues = <T extends {},>(context: DataContext<T>): Partial<T> => {
  return observableMemo(context?.observable, () => context?.getDirtyValue())
}

export const useErrors = <T,>(context: DataContext<T>): DataError[] =>
  observableMemo(context?.observable, () => context?.errors)

