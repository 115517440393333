import React, { useId } from 'react'
import { createPortal } from 'react-dom'
import { InputClassNameContext, Label, withInput, withInputs } from '../RenderContext'
import { FooterPortalContext } from 'components/Style/Layout/new'
import { classNames } from 'components/Util'
import { useErrors } from '../Data'
import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'

export const FormIdContext = React.createContext<string>(null)

export interface SubmittableFormContext {
  onSubmit: () => Promise<void>
}

const Stacked: React.FunctionComponent<{ form: SubmittableFormContext, children: React.ReactNode }> = ({ form, children }) => {
  const formId = useId()

  const submitHandler = React.useCallback((ev) => {
    //TODO: manage the busy state here
    ev.preventDefault()
    form.onSubmit()
  }, [form?.onSubmit])

  return <FormIdContext.Provider value={formId}>
    <form id={formId} onSubmit={submitHandler}>
      <div className="space-y-12">
        {children}
      </div>
    </form>
  </FormIdContext.Provider>
}

const Section: React.FunctionComponent<{ title: React.ReactNode, description?: React.ReactNode, children: React.ReactNode }> = withInputs(({ title, description, children, inputs }) => {
  return <div className="space-y-10 divide-y divide-gray-900/10">
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>
        {description &&
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {description}
          </p>
        }
      </div>

      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-6 sm:p-8">

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-md">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
})


const InputClassName = "block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
const Input: React.FunctionComponent<{ label: React.ReactNode, prefix?: React.ReactNode, suffix?: React.ReactNode, description?: React.ReactNode, children: React.ReactNode }> = withInput(({ label, prefix, suffix, description, children, input }) => {

  const errors = useErrors(input?.field)
  const invalid = errors && errors.length > 0

  return <div className="col-span-4 mt-3">
    <Label className={classNames("block text-sm font-medium leading-6", invalid ? "text-red-900" : "text-gray-900")}>
      {label}
    </Label>

    {description &&
      <div className="mt-1 text-xs text-gray-400">
        {description}
      </div>
    }

    <div className="mt-2">
      <div className={classNames(
        "flex rounded-md shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset sm:max-w-md",
        invalid ? "ring-red-300 focus-within:ring-red-600" : "ring-gray-300  focus-within:ring-indigo-600"
      )}>
        <div className="pl-1"></div>

        {prefix &&
          <div className="text-sm py-1.5 px-1 mx-1 sm:text-sm sm:leading-6 border-r">
            {prefix}
          </div>
        }

        <InputClassNameContext.Provider
          value={InputClassName}
          children={children} />

        {invalid &&
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ExclaimationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        }

        {suffix &&
          <div className="text-sm py-1.5 px-1 mx-1 sm:text-sm sm:leading-6 border-l">
            {suffix}
          </div>
        }
      </div>
    </div>
    {errors &&
      <p className="mt-2 text-sm text-red-600">{errors.map(e => e.message).join(", ")}</p>
    }

  </div>
})


const OptionSelect: React.FunctionComponent<{ label?: React.ReactNode, description?: React.ReactNode, children: React.ReactNode }> = ({ label, description, children }) => {
  return <div className="col-span-4">
    <Label>
      <div className="font-medium text-gray-900">
        {label}
      </div>

      <InputClassNameContext.Provider
        value="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        children={children} />

      <div className="text-sm leading-6">
        {description &&
          <div className="text-gray-500">{description}</div>
        }
      </div>
    </Label>
  </div>
}

const CheckboxSet: React.FunctionComponent<{ title?: React.ReactNode, description?: React.ReactNode, children: React.ReactNode }> = ({ title, description, children }) => {

  return <div className="col-span-4">
    <fieldset>
      {title &&
        <legend className="text-sm font-semibold leading-6 text-gray-900">{title}</legend>
      }
      {description &&
        <div className="mt-1 text-sm leading-6 text-gray-600">
          {description}
        </div>
      }
      <div className="mt-2 space-y-2">
        {children}
      </div>
    </fieldset>

  </div>
}

const CheckboxClassName = "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"

const Checkbox: React.FunctionComponent<{ label: React.ReactNode, description?: React.ReactNode, children: React.ReactNode }> = withInput(({ label, description, children }) => {
  return <Label>
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">

        <InputClassNameContext.Provider
          value={CheckboxClassName}
          children={children} />
      </div>
      <div className="text-sm leading-6">
        <div className="font-medium text-gray-900">
          {label}
        </div>
        {description &&
          <div className="text-gray-500">{description}</div>
        }
      </div>
    </div>
  </Label>
})

const StickyFooter: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const footer = React.useContext(FooterPortalContext)

  const inner = <div className="sticky bottom-0 left-0 right-0 p-3 bg-white border-t border-grey-200 flex flex-row space-x-2">
    {children}
  </div>

  return <>
    {footer?.current
      ? createPortal(inner, footer.current)
      : inner
    }
  </>
}

export default Object.assign(Stacked, {
  Input, Checkbox, CheckboxSet, Section, StickyFooter, OptionSelect,
  InputClassName, CheckboxClassName
})
