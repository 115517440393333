import Components from '../../../../components/Bootable'

export default {
  ...Components,

  // VanFinder: i(async () => (await import('components/vanfinder/VanFinderApp')).VanFinderApp),
  VenueListing: () => import("components/public/VenueListing"),
  UiExamples: () => import('components/Util/UiExamples'),
  ServiceOrderList: () => import('components/admin/Orders/ServiceOrderList'),
  RecentOrderList: () => import('components/admin/Orders/RecentOrderList'),
  UncapturedOrderList: () => import('components/admin/Orders/UncapturedOrderList'),
  TagRuleEditor: () => import('components/admin/Collections/TagRuleEditor/LegacyEditor'),
  SlotRules: () => import('components/admin/Collections/SlotRulesEditor/LegacyEditor'),
  GraphQlExplorer: () => import('components/admin/GraphQlExplorer'),
  MenuBuilder: () => import('components/admin/Collections/MenuBuilder/MenuBuilder'),
  LegacyAdminFrame: () => import('components/admin/AdminFrame/LegacyAdminFrame'),
  AdminSettings: () => import('components/admin/AdminSettings'),
  AdminChangePassword: () => import('components/admin/AdminChangePassword'),
  AdminCollectionLocations: () => import('components/admin/CollectionLocations'),
  AdminCollectionLocationNew: () => import('components/admin/CollectionLocations/CollectionLocationNew'),
  AdminCollectionLocationEdit: () => import('components/admin/CollectionLocations/CollectionLocationEdit'),
  AdminCollectionShow: () => import('components/admin/Collections/CollectionShow'),
  AdminCollectionSwimlanes: () => import('components/admin/Collections/CollectionShow/QueueTiming'),
  AdminCollectionNew: () => import('components/admin/Collections/CollectionNew'),
  AdminCollectionEdit: () => import('components/admin/Collections/CollectionEdit'),
  AdminMenuItems: () => import('components/admin/AdminMenuItems'),
  AdminMenuItemEdit: () => import('components/admin/AdminMenuItems/MenuItemEdit'),
  AdminMenuItemNew: () => import('components/admin/AdminMenuItems/MenuItemNew'),
  AdminOrderShow: () => import('components/admin/Orders/AdminOrderShow'),
  AdminOrderNew: () => import('components/admin/Orders/AdminOrderNew'),
  AdminOrderEdit: () => import('components/admin/Orders/AdminOrderEdit'),
  AdminOrderItems: () => import('components/admin/Orders/AdminOrderEdit/AdminOrderItems'),
  AdminPermalinks: () => import('components/admin/AdminPermalinks'),
  AdminPermalinkEdit: () => import('components/admin/AdminPermalinks/PermalinkEdit'),
  AdminPermalinkNew: () => import('components/admin/AdminPermalinks/PermalinkNew'),
  Card: () => import('components/Style/Card'),
  UtilisationBar: () => import('components/admin/UtilisationBar'),
  PaymentStatusButton: () => import('components/admin/Orders/PaymentStatusButtonWrapped'),
  ManagementFrame: () => import('components/management/ManagementFrame'),
  PaymentForm: () => import('components/public/PaymentForm'),
  OrderSummaryForToken: () => import('components/public/checkout/OrderSummaryForToken'),
  PublicApp: () => import('components/public/PublicApp'),
  PublicOrderShow: () => import('components/public/order/PublicOrderShow'),
}
